// extracted by mini-css-extract-plugin
export var button = "registration-page-module--button--85e17";
export var ccsDiv = "registration-page-module--ccsDiv--940c0";
export var contactDetailsIcon = "registration-page-module--contactDetailsIcon--abd43";
export var contactFormDiv = "registration-page-module--contactFormDiv--cc1e2";
export var contactFormInputsDiv = "registration-page-module--contactFormInputsDiv--57aae";
export var displayDiv = "registration-page-module--displayDiv--986d0";
export var footerContentDiv = "registration-page-module--footerContentDiv--7d235";
export var footerDiv = "registration-page-module--footerDiv--6fd0c";
export var footerLDiv = "registration-page-module--footerLDiv--e9704";
export var footerLinksDiv = "registration-page-module--footerLinksDiv--8f7f7";
export var footerLogoDiv = "registration-page-module--footerLogoDiv--4c75d";
export var footerRDiv = "registration-page-module--footerRDiv--c071e";
export var formTitleDiv = "registration-page-module--formTitleDiv--561d5";
export var fullNameDiv = "registration-page-module--fullNameDiv--2425c";
export var gcsSymbolIcon = "registration-page-module--gcsSymbolIcon--cc1c5";
export var globalCareSupply = "registration-page-module--globalCareSupply--35a59";
export var input = "registration-page-module--input--1dc62";
export var inputFieldWIthLabelDiv = "registration-page-module--inputFieldWIthLabelDiv--cd953";
export var inputFieldWIthLabelDiv9 = "registration-page-module--inputFieldWIthLabelDiv9--ee772";
export var inputLabelDiv = "registration-page-module--inputLabelDiv--2c61a";
export var inputLabelDiv9 = "registration-page-module--inputLabelDiv9--fe9e6";
export var inputRowDiv = "registration-page-module--inputRowDiv--6481d";
export var inputRowDiv4 = "registration-page-module--inputRowDiv4--b9817";
export var inputSelect = "registration-page-module--inputSelect--b820e";
export var logoA = "registration-page-module--logoA--31c4e";
export var passwordsShouldContainAMin = "registration-page-module--passwordsShouldContainAMin--c60ee";
export var patternsIcon = "registration-page-module--patternsIcon--c4863";
export var privacyPolicy = "registration-page-module--privacyPolicy--b4fdf";
export var registerContentDiv = "registration-page-module--registerContentDiv--dfb3c";
export var registerSectionDiv = "registration-page-module--registerSectionDiv--0e2fb";
export var registrationPageDiv = "registration-page-module--registrationPageDiv--6214f";
export var shippingPolicyA = "registration-page-module--shippingPolicyA--ade67";
export var visaIcon = "registration-page-module--visaIcon--16f0c";