import * as React from "react";

import Layout from "../components/Base/Layout";
import RegistrationPage from '../components/Registration/registration-page'
import { SEO } from "../components/Base/Seo";
import UnAuthContent from "../components/Login/UnAuthContent";

const Register = () => {
  return (
    <Layout>
      <UnAuthContent>
        <RegistrationPage></RegistrationPage>
      </UnAuthContent>
    </Layout>
  );
}

export default Register

export const Head = () => (
  <SEO title="Global Care Supply | Register" description="Register here to shop." />
)